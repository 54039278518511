import { convertToRaw } from "draft-js";
import { Editor } from "draft-js";
import jsPDF from "jspdf";
import React from "react";
import { eduNsw } from "./fonts/eduNsw";
import { DANCING_FONT } from "./fonts/Dancing";
import { font } from "./fonts/DancingScript-VariableFont_wght-normal";

import { FONT_SIZE } from "./Helper/constants";
import { ItemKonvaToJsPDF } from "./Helper/functions";

const DataToJSPDFComponent = ({
  listData,
  editorState,
  printCharacters,
  sharedY,
}) => {
  return (
    <>
      <button
        className="btnPDF btnSpace"
        onClick={(e) => {
          const pdf = new jsPDF("p", "mm", "a4");
          pdf.addFileToVFS("EduNSWACTFoundation-Regular.ttf", eduNsw);
          pdf.addFileToVFS("Ubuntu-Regular.ttf", "normal");
          //pdf.addFileToVFS("DancingScript-VariableFont_wght.ttf", DANCING_FONT);
          var callAddFont = function () {
            this.addFileToVFS('DancingScript-VariableFont_wght-normal.ttf', font);
            this.addFont('DancingScript-VariableFont_wght-normal.ttf', 'DancingScript-VariableFont_wght', 'normal');
            };
            jsPDF.API.events.push(['addFonts', callAddFont])
          let numberPage=0;
          pdf.setFontSize(14).setFont("arial", "normal", 500);
          const multiplier = 2.9999777779423864;
          const itemPDF = [];
          const redactionItem = [];
            listData.map(lD=>{
                if (lD.type !== "redaction") {
                    itemPDF.push(lD);
                  } else {
                    redactionItem.push(lD);
                  }
            })
          redactionItem.map((e) => {
            if (e.type === "redaction" && e.spec === "add") {
                ItemKonvaToJsPDF(itemPDF,multiplier,pdf,numberPage)
                numberPage+=1
              let x = e.x / multiplier;
              let y = e.y / multiplier;
              const endX = pdf.internal.pageSize.width - x * 2;
              console.log('diff',{x,endX})
              pdf.setDrawColor("#fff");
              pdf.setLineWidth(1);
              pdf.line(x, y - 10, x, y + 200);
              pdf.line(endX, y - 10, endX, y + 200);
              const blocks = convertToRaw(
                editorState.getCurrentContent()
              ).blocks;

              let arrOfTextData = [];
              let sumRows = 0;
              let rowLeftOnPage = 0;
              blocks.map((block, index) => {
                let textWithSeparator = "";
                let addLengthToText = 0;
                const { inlineStyleRanges, text, data } = block;
                
                textWithSeparator += text;
                if (inlineStyleRanges !== 0) {
                  inlineStyleRanges.map((insr) => {
                    const { offset, length, style } = insr;
                    switch (style) {
                      case "BOLD":
                        textWithSeparator = [
                          textWithSeparator.slice(0, offset + addLengthToText),
                          "**",
                          textWithSeparator.slice(offset + addLengthToText),
                        ].join("");
                        //debugger
                        addLengthToText += 2;
                        textWithSeparator = [
                          textWithSeparator.slice(
                            0,
                            length + offset + addLengthToText
                          ),
                          "**",
                          textWithSeparator.slice(
                            length + offset + addLengthToText
                          ),
                        ].join("");
                        //debugger
                        addLengthToText += 2;
                        //debugger
                        break;
                      case "ITALIC":
                       // debugger
                        textWithSeparator = [
                          textWithSeparator.slice(0, offset),
                          "//",
                          textWithSeparator.slice(offset),
                        ].join("");
                        console.log("textWithSeparator",textWithSeparator)
                        //debugger
                        addLengthToText += 2;
                        //debugger
                        textWithSeparator = [
                          textWithSeparator.slice(
                            0,
                            length + offset + addLengthToText
                          ),
                          "//",
                          textWithSeparator.slice(
                            length + offset + addLengthToText
                          ),
                        ].join("");
                        addLengthToText += 2;
                        //debugger
                        break;
                      default:
                        break;
                    }
                  });
                }
                //debugger
                let charsMapLength = 0;
                let position = 0;
                let isBold = false;
                let isItalic = false;

                const splitTextWithoutBoldMarks = pdf.splitTextToSize(
                  text,
                  endX,
                  {
                     fontSize:13 
                  }
                );
                console.log('text 114',{text, splitTextWithoutBoldMarks})
                const textRows = splitTextWithoutBoldMarks.map((row, i) => {
                  const splitRow = row.split("");
                  const chars = splitRow.map((char, j) => {
                    position = charsMapLength + j + i;
                    //debugger
                    //console.log("position", position);
                    let currentChar = textWithSeparator.charAt(position);
                    //console.log("currentChar", currentChar);
                    if (currentChar === "*" || currentChar === "/") {
                      const spyNextChar = textWithSeparator.charAt(
                        position + 1
                      );
                      const spyDoubleNextChar = textWithSeparator.charAt(
                        position + 3
                      );
                      //debugger
                      //console.log("spyNextChar", spyNextChar);
                      if (spyNextChar === "*" || currentChar === "/") {
                        // double asterix marker exist on these position's so we toggle the bold state
                        isBold = (spyDoubleNextChar==="*" || currentChar === "*") ?!isBold : isBold;
                        isItalic = (spyDoubleNextChar==="/" ||currentChar === "/") ? !isItalic : isItalic ;
                        currentChar = isBold && isItalic ? textWithSeparator.charAt(position + 4) :  textWithSeparator.charAt(position + 2);
                        //debugger
                        // now we remove the markers, so loop jumps to the next real printable char
                        let removeMarks = textWithSeparator.split("");
                        spyDoubleNextChar==="/" || spyDoubleNextChar==="*" ? removeMarks.splice(position, 4) :  removeMarks.splice(position, 2);
                        //debugger
                        textWithSeparator = removeMarks.join("");
                      }
                    }
                    //debugger
                    return {
                      char: currentChar,
                      bold: isBold,
                      italic: isItalic,
                    };
                  });
                  //debugger
                  charsMapLength += splitRow.length;

                  return { ...chars };
                });
                const endOfPage = listData.find(
                  (e) => e.type === "redaction" && e.spec === "end"
                );
                let totalRows = Math.floor(
                  (endOfPage.y / 3 - (y + sumRows * 10)) / 10
                );
                rowLeftOnPage = totalRows;
                if (textRows.length  <= totalRows +1 || totalRows === -1 ) {

                  sumRows += 1;
                  rowLeftOnPage -= 1;
                  if (rowLeftOnPage === -1 && textWithSeparator !=="") {
                    pdf.addPage("a4");
                    y = e.y / multiplier;
                    sumRows = 0;
                    rowLeftOnPage = totalRows - 1;
                    //debugger;
                    ItemKonvaToJsPDF(itemPDF,multiplier,pdf,numberPage)
                    numberPage+=1
                    printCharacters(
                      pdf,
                      textRows,
                      y + sumRows * 10,
                      x,
                      FONT_SIZE,
                      10,
                      data,
                      text,
                      //sumRows,
                      listData,
                      sharedY
                    );
                  }else{
                    printCharacters(
                      pdf,
                      textRows,
                      y + sumRows * 10,
                      x,
                      FONT_SIZE,
                      10,
                      data,
                      text,
                      //sumRows,
                      listData,
                      sharedY
                    );
                  }
                } else {
                  debugger
                  for (let i = 0; i <= textRows.length; i++) {
                    console.log("i", i);
                    //debugger
                    printCharacters(
                      pdf,
                      textRows.slice(i, i + 1),
                      y + sumRows * 10,
                      x,
                      FONT_SIZE,
                      10,
                      data,
                      text,
                      //sumRows,
                      listData,
                      sharedY
                    );
                    if (
                      (totalRows % i === 0 || i % totalRows === 0) &&
                      rowLeftOnPage === 0
                    ) {
                      //   //debugger;
                      pdf.addPage("a4");
                      debugger
                      y = e.y / multiplier;
                      sumRows = 0;
                      rowLeftOnPage = totalRows - 1;
                      ItemKonvaToJsPDF(itemPDF,multiplier,pdf,numberPage)
                      numberPage+=1
                      //debugger;
                    } else {
                      sumRows += 1;
                      rowLeftOnPage -= 1;
                    }
                  }
                }
              });
            }
            // pdf.internal.write(0, "Tw");
          });
          const pageCount = pdf.internal.pages
          for(let i=1;i<pageCount.length;i++){
            pdf.setPage(i)
            pdf.text(`Page ${i} sur ${pageCount.length-1}`,550/multiplier,870/multiplier)
          }
          const urlToOpen = pdf.output("datauri");
          let iframe =
            "<iframe width='100%' height='100%' src='" +
            urlToOpen +
            "'></iframe>";
          let x = window.open();
          x.document.open();
          x.document.write(iframe);
          x.document.close();

          // pdf.fromHTML(string);
          // pdf.save("pdf");
        }}
      >
        Save And Preview
      </button>
    </>
  );
};

export default DataToJSPDFComponent;
